import DateTime from './DateTime';

class Env {
    get(name) {
        name = `REACT_APP_${name}`.toUpperCase();
        return window.REACT_APP ? window.REACT_APP.env[name]: process.env[name];
    }

    getVersion() {
        return this.isProduction() ? this.get('version'): DateTime.getCurrentTimestamp();
    }

    isProduction() {
        return this.is('env','production');
    }

    isLocal() {
        return this.is('env','local');
    }

    isDevelopment() {
        return !this.isProduction();
    }

    isSRE() {
        return this.is('sre','true');
    }

    isDesktop() {
        return this.is('platform','desktop');
    }

    isMobile() {
        return this.is('platform','mobile');
    }

    isApp() {
        return this.is('platform','app');
    }

    getPlatform() {
        return this.get('platform');
    }

    getPublicUrl() {
        return this.get('public_url');
    }

    is(name, value) {
        return this.get(name) === value;
    }

    getAppName() {
        return this.get('name');
    }
  }

  export { Env };
  export default new Env();