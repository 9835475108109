import React from 'react';
import Translate from '../../../../../modules/Core/Components/Translate';

const ExportDefault = function(props) {
    return (
        <section className="pricing-sec padding-top padding-bottom" id="pricing-sec">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10 offset-md-1 pricing-heading-area text-center">
                        <h4 className="heading"><Translate app="catalogue" entity="item" eslug="section5" scope="default" slug="title" auto/></h4>
                        <p className="text">
                            <Translate app="catalogue" entity="item" eslug="section5" scope="default" slug="sub-title" auto/>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExportDefault;