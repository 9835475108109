import Dev from './Dev';

class Value {
    values = [];

    set(values) {
        this.values = values;
        return this;
    }

    get() {
        return this.values || [];
    }

    getValue(slug, defaultValue, values = null) {
        defaultValue = Dev.define(defaultValue,null);
        values = values ? values: this.get();
        let value = null;

        values.every(data => {
            if (data.slug === slug) {
                value = data;
                return false;
            }

            return true;
        });

        return value ? value.data: defaultValue;
    }    
}

export { Value }
export default new Value();
