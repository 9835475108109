import ShareApi from '../../Api/Share';

class File 
{    
    scope(options) {
        return new Promise((success, reject) => {
            options = options || {};

            const { 
                account,
                app,
                entity,
                entity_slug,
                scope
            } = options;
    
            ShareApi.file.scope(account,app,entity,entity_slug,scope).then(r => {
                if (r.isValid()) {
                    const data = r.getData();
                    success(data);
                } else {
                    reject();
                }
            }).catch(err => {
                reject(err);
            });
        });
    }
}

export { File };
export default new File();