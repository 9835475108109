import React from "react";

const ExportDefault = (props) => {
  const { component, className, block } = props;
  const renderer = component.getRenderer();
  let content = "";

  if (renderer) {
    content = renderer(props);
  } else {
    const tranlation = component.translate();

    content = tranlation.translation || "";

    if (component.isHtml(tranlation)) {
      content = block ? (
        <div
          className={className}
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      ) : (
        <span
          className={className}
          dangerouslySetInnerHTML={{ __html: content }}
        ></span>
      );
    }
  }

  return content;
};

export default ExportDefault;
