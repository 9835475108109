import String from './String';
import Language from './Language';
import Env from './Env';
import ShareApi from '../../Api/Share';

class Literal {
    literals = [];

    set(literals) {
        this.literals = literals;
        return this;
    }

    get() {
        return this.literals || [];
    }

    trans(slug) {
        let value = slug;

        this.get().every(literal => {
            if (literal.slug === slug) {
                value = literal.translation || slug;
                return false;
            }

            return true;
        });

        return value;
    }
    
    ucfirst(slug) {
        const trans = this.trans(slug);
        return String.ucfirst(trans || slug);
    }

    retrieve() {
        return new Promise((resolve, reject) => {
            const lang = Language.getLang();
            const app = Env.get('name');
            const utilsAccount = Env.get('default_account','system');
    
            ShareApi.literal.app(utilsAccount,lang,app)
                .then(response => {
                    this.set(response.getData());
                    resolve();
                })
                .catch(e => reject(e));
        })
    }

    changeLanguage(language) {
        Language.setLang(language);
        return this.retrieve();
    }
}

export { Literal };
export default new Literal();