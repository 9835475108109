import React from 'react';

const ExportDefault = function(props) {
    const { component } = props;
    const Value = component.getHelpers('value');
    const map = component.getHelpers('value').getValue('map');
    const phone = Value.getValue('phone');
    const email = Value.getValue('email');
    const address = Value.getValue('address');

    return (
        <section className="contact-sec padding-top" id="contact-sec">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-7 wow fadeInLeft">
                        <h4 className="heading text-center text-lg-left">
                            {component.ucfirst('contact-with-us')}
                        </h4>
                        <div dangerouslySetInnerHTML={{__html: map}}></div>
                    </div>
                    <div className="col-12 col-lg-5 text-center text-lg-left position-relative d-flex align-items-center">
                        <div className="contact-details wow fadeInRight">
                            <ul>
                                <li><i className="las la-map-marker addr color-blue"></i><a href={`http://maps.google.com/?q=${address}`}>{address}</a></li>
                                <li><i className="las la-phone-volume phone color-yellow"></i><a href={`tel:${phone}`}>{phone}</a></li>
                                <li style={{"marginBottom": "30px"}}><i className="las la-paper-plane email color-green"></i><a href={`mailto:${email}`}>{email}</a></li>
                                <li><img src="design-agency/img/contact-pot.png" className="wow slideInRight"  data-wow-delay=".5" alt="contact-pot" /></li>
                            </ul>
                        </div>
                        <img src="design-agency/img/contact-background.png" className="contact-background" alt="contact" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExportDefault;