import React from 'react';
import Translate from '../../../../../modules/Core/Components/Translate';

const ExportDefault = function(props) {
    return (
      <section id="feature-sec" className="feature-sec padding-top padding-bottom">
          <div className="container">
              <div className="row">
                  <div className="col-lg-10 offset-lg-1 col-md-8 offset-md-2 col-sm-12 text-center">
                      <div className="text">
                          <div className="home-text text-black">
                              <h4 className="heading"><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="title" auto/></h4>
                              <p className="text"><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="sub-title" auto/></p>
                          </div>
                      </div>
                  </div>
              </div>
      
              <div className="row padding-top features-cards">
                  <div className="col-12 col-lg-6">
                      <div className="card box text-center">
                          <div className="feature-icon text-center">
                            <img src="design-agency/img/google-logo.png" style={{"maxWidth": "100px"}} alt="google-logo"/>
                          </div>
                          <div className="card-body">
                              <h2><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-1-title" auto/></h2>
                              <p className="card-text sub-heading text-black"><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-1-content" auto/></p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-6">
                      <div className="card box text-center">
                          <div className="feature-icon text-center">
                            <img src="design-agency/img/google-maps-logo.png" style={{"maxWidth": "110px"}} alt="google-maps-logo"/>
                          </div>
                          <div className="card-body">
                              <h2><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-2-title" auto/></h2>
                              <p className="card-text sub-heading text-black"><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-2-content" auto/></p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-6">
                      <div className="card box text-center">
                          <div className="feature-icon text-center">
                            <img src="design-agency/img/facebook-logo.png" style={{"maxWidth": "50px"}} alt="facebook-logo"/>
                          </div>
                          <div className="card-body">
                              <h2><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-3-title" auto/></h2>
                              <p className="card-text sub-heading text-black"><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-3-content" auto/></p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-6">
                      <div className="card box text-center">
                          <div className="feature-icon text-center">
                            <img src="design-agency/img/email-logo.png" style={{"maxWidth": "125px"}} alt="email-logo"/>
                          </div>
                          <div className="card-body">
                              <h2><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-4-title" auto/></h2>
                              <p className="card-text sub-heading text-black"><Translate app="catalogue" entity="item" eslug="section2" scope="default" slug="box-4-content" auto/></p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );
};

export default ExportDefault;