import Api from '../Core/Helpers/Api';

const shareApi = Api.create('share');

const ExportDefault = {
  value: {
    account: function(account, options) {
      return shareApi.get(`value/account/${account}`,options);
    },
    app: function(account, app, options) {
      return shareApi.get(`value/account/${account}/app/${app}`,options);
    },
    slug: function(account, app, slug, options) {
      return shareApi.get(`value/account/${account}/app/${app}/slug/${slug}`,options);
    },
  },
  literal: {
    app: function(account, lang, app, options) {
      return shareApi.get(`literal/account/${account}/lang/${lang}/app/${app}`,options);
    },
    /*
    slug: function(account, lang, app, slug, options) {
      return shareApi.get(`literal/account/${account}/lang/${lang}/app/${app}/slug/${slug}`,options);
    },
    */
  },
  translation: {
    scope: function(account, app, lang, entity, entity_slug, scope, options) {
      return shareApi.get(`translation/account/${account}/app/${app}/lang/${lang}/entity/${entity}/slug/${entity_slug}/scope/${scope}`,options);
    },
    slug: function(account, app, lang, entity, entity_slug, scope, slug, options) {
      return shareApi.get(`translation/account/${account}/app/${app}/lang/${lang}/entity/${entity}/slug/${entity_slug}/scope/${scope}/slug/${slug}`,options);
    },
  },
  file: {
    scope: function(account, app, entity, eslug, scope, options) {
      return shareApi.get(`file/account/${account}/app/${app}/entity/${entity}/eslug/${eslug}/scope/${scope}`,options);
    },
  },
  language: {
    app: function(account, app, options) {
      return shareApi.get(`language/account/${account}/app/${app}`,options);
    }
  }
};

export default ExportDefault;