class MyString {
    isString(text) {
        return typeof text === 'string';
    }

    capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    cammelCase(string, delimiter) {
        delimiter = delimiter || ' ';
        string = string.split(delimiter);

        for (var i in string) {
            string[i] = this.capitalize(string[i]);
        }

        return string.join('');
    }

    ucfirst(string) {
        string = string || '';
        return string.substring(0,1).toUpperCase() + string.substring(1)
    }
  }

  export { MyString };
  export default new MyString();