import React from 'react';

const ExportDefault = function(props) {
    const { component } = props;
    const Value = component.getHelpers('value');
    const year = component.getHelpers('date-time').getCurrentYear();

    return (
        <React.Fragment>
            <footer className="footer-style-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 text-center text-lg-left order-2 order-lg-1">
                            <p className="company-about fadeIn mt-3 mt-lg-5">© {year} {Value.getValue('brand-name')}.</p>
                        </div>
                        
                    </div>
                </div>
            </footer>
            <div className="aimated-cursor">
                <div className="cursor">
                    <div className="cursor-loader"></div>
                </div>
            </div>
            <span className="scroll-top-arrow"><i className="fas fa-angle-up"></i></span>
        </React.Fragment>
    );
};

export default ExportDefault;