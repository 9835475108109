import { Component } from '../Component';
import axios from 'axios';
import Api from '../Helpers/Api';
import ShareApi from '../../Api/Share';
import AuthApi from '../../Api/Auth';

export default class App extends Component {
    persist = false;

    constructor(props) {
        super(props);

        this.setId('app');
        this.getHelpers('const').set('app',this);
        this.setupInvalidResponse();
    }

    componentDidMount() {
        super.componentDidMount();

        window.onresize = () => {
            //this.defineTemplate();
        }

        this.defineTemplate().boot();
    }

    defineTemplate() {
        const currentScope = this.getHelpers('template').getScope();
        const newScope = this.getOnResizeResolusion(window.innerWidth);

        if (currentScope !== newScope) {
            this.getHelpers('template').setScope(newScope);
        }

        return this;
    }

    getOnResizeResolusion() {
        return 'default';
    }

    setupInvalidResponse() {
        const app = this;

        Api.onInvalidResponse = function(r) {
            app.onInvalidResponse(r);
        };

        return this;
    }

    onInvalidResponse(r) {
        const { type } = r.getData();
        const Url = this.getHelpers('url');
        const Env = this.getHelpers('env');
        const page = this.getPage();

        if (r.isOffline()) {
            //
        }

        if (page && page.isFullAuth() && type === 'not-allowed') {
            Url.redirect(Env.get('login_url'));
        }
    }

    boot() {
        const app = this.getHelpers('env').get('name');
        const account = this.getHelpers('env').get('default_account','system');

        axios
            .all([
                ShareApi.value.app(account,app),
            ])
            .then(([values]) => {
                this.getHelpers('value').set(values.getData());
                return this.getHelpers('literal').retrieve();
            })
            .then(() => {
                this.setLoaded();
                return AuthApi.status();
            })
            .then((status) => {
                this.getHelpers('auth').set(status.getData());
                this.notifyAuth({boot:true});
                //return ShareApi.value.slug(account,app,'cache');
            })
            .catch(errors => console.log('app-boot: ',errors))
        ;
    }
}