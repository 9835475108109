import React from 'react';
import Translate from '../../../../../modules/Core/Components/Translate';

const ExportDefault = function(props) {
    const { component } = props;
    const Value = component.getHelpers('value');

    return (
      <section id="stats" className="stats bg-light-gray padding-top">
          <div className="container">
              <div className="row m-0">
                  <div className="col-12 col-md-10 offset-md-1 col-lg-7 offset-lg-0 text-left p-0 text-center text-lg-left">
                      <div className="stats-text wow slideInLeft">
                          <div className="home-text text-black">
                              <span className="sub-heading"><Translate app="catalogue" entity="item" eslug="section3" scope="default" slug="title" auto/></span>
                              <h4 className="heading mt-3 mb-4"><Translate app="catalogue" entity="item" eslug="section3" scope="default" slug="sub-title" auto/></h4>
                              <p className="text mb-3"><Translate app="catalogue" entity="item" eslug="section3" scope="default" slug="content" auto/></p>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 col-lg-5 d-flex justify-content-end">
                      <div className="plant-img">
                              <img src="design-agency/img/plant.png" className="wow slideInRight" alt="img" data-wow-delay=".5s" />
                      </div>
                  </div>
              </div>

              <div className="row padding-top-half">
                  <div className="col-12">
                      <div className="row">
                          <div className="col-12 col-md-4 wow fadeInUp">
                              <div className="stats-box d-flex">
                                  <div className="stats-icon">
                                      <i className="fas fa-users color-green"></i>
                                  </div>
                                  <div className="stats-box-text ml-4">
                                      <h1 className="numbering">{Value.getValue('happy-customers')}+</h1>
                                      <p className="stat-sub-heading">{component.ucfirst('happy customers')}</p>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 col-md-4 mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".2">
                              <div className="stats-box d-flex">
                                  <div className="stats-icon">
                                      <i className="fas fa-map color-blue"></i>
                                  </div>
                                  <div className="stats-box-text ml-4">
                                      <h1 className="numbering">{Value.getValue('number-of-pins')}+</h1>
                                      <p className="stat-sub-heading">{component.ucfirst('pins-on-map')}</p>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 col-md-4 mt-4 mt-md-0 wow fadeInUp" data-wow-delay=".4">
                              <div className="stats-box d-flex">
                                  <div className="stats-icon">
                                      <i className="fas fa-check color-yellow"></i>
                                  </div>
                                  <div className="stats-box-text ml-4">
                                      <h1 className="numbering">{Value.getValue('completed-projects')}+</h1>
                                      <p className="stat-sub-heading">{component.ucfirst('completed projects')}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    );
};

export default ExportDefault;