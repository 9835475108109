import MyMath from './Math';

class DateTime {
    create(date) {
        return date ? new Date(date): new Date();
    }

    getCurrentTimestamp() {
        return (new Date()).getTime();
    }

    getCurrentTime() {
        const date = new Date();
        return this.createDateTime(date);
    }

    getCurrentYear() {
        const date = new Date();
        return MyMath.numpad(date.getFullYear());
    }

    createDate(date) {
        const year = MyMath.numpad(date.getFullYear());
        const month = MyMath.numpad(date.getMonth() + 1);
        const day = MyMath.numpad(date.getDate());

        return [year,month,day].join('-');
    }

    createTime(date) {
        const hours = MyMath.numpad(date.getHours());
        const minutes = MyMath.numpad(date.getMinutes());
        const seconds = MyMath.numpad(date.getSeconds());

        return [hours,minutes,seconds].join(':');
    }

    createDateTime(date) {
        return [this.createDate(date)].concat(this.createTime(date)).join(' ');
    }
  }

  export { DateTime }
  export default new DateTime();