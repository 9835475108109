import React from "react";

const ExportDefault = function (props) {
  const { component } = props;
  const Value = component.getHelpers("value");
  const year = component.getHelpers("date-time").getCurrentYear();
  const phone = Value.getValue("phone");
  const email = Value.getValue("email");
  const brandName = Value.getValue("brand-name");

  return (
    <header id="home" className="cursor-light">
      <div className="inner-header nav-icon no-shadow">
        <span className="sidemenu_btn link" id="sidemenu_toggle">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div className="side-menu hidden side-menu-opacity">
        <div className="bg-overlay"></div>
        <div className="inner-wrapper">
          <span className="btn-close" id="btn_sideNavClose">
            <i></i>
            <i></i>
          </span>
          <div className="container">
            <div className="row w-100 side-menu-inner-content">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <a href="/" className="navbar-brand">
                  <img src="design-agency/img/logo.png" alt="logo" />
                </a>
              </div>
              <div className="col-12 col-lg-8">
                <nav className="side-nav w-100">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#home">
                        {component.ucfirst("home")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#feature-sec">
                        {component.ucfirst("scope")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#stats">
                        {component.ucfirst("aims")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#blog-sec">
                        {component.ucfirst("blog")}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link scroll" href="#contact-sec">
                        {component.ucfirst("contact")}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-12 col-lg-4 d-flex align-items-center">
                <div className="side-footer text-white w-100">
                  <div className="menu-company-details">
                    <span>
                      <a href="tel:+302100105411">{phone}</a>
                    </span>
                    <span>
                      <a href="mailto:info@localvoice.eu">{email}</a>
                    </span>
                  </div>
                  <p className="text-white">
                    &copy; {year} {brandName}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span id="close_side_menu"></span>
    </header>
  );
};

export default ExportDefault;
