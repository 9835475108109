import React from 'react';

const ExportDefault = function(props) {
    const { component } = props;

    return (
        <section id="blog-sec" className="blog-sec padding-top padding-bottom bg-light-gray">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="blog-img wow fadeInLeft">
                            <img src="design-agency/img/blog-mokeup.png" alt="blog-mokeup"/>
                            <img src="design-agency/img/blog-image.png" id="mokeup-img"  alt="blog"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-0 text-center text-lg-left">
                        <div className="blog-detail wow fadeInRight">
                            <h4 className="heading">Blog</h4>
                            <p className="text">{component.ucfirst('soon-available')}</p>
                            {
                                /*
                                <a className="btn blue-btn btn-hvr btn-hvr-green rounded-pill" href="blog.html">Learn More
                                    <span></span><span></span><span></span><span></span>
                                </a>
                                */
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ExportDefault;