import React from 'react';
import Translate from '../../../../../modules/Core/Components/Translate';

const ExportDefault = function(props) {
    return (
      <section className="banner-slider position-relative cursor-light" id="banner-slider">
        <div className="row banner-slider-row no-gutters">
          <div className="col-12 col-lg-6 bg-blue banner-left-area d-flex justify-content-center align-items-center wow slideInLeft" data-wow-delay=".8s">
              <div className="left-banner">
                  <div className="container">
                      <div className="detail-text">
                          <div className="inner-content position-relative text-center"  data-depth="0.05">
                              <h4 className="heading"><img src="design-agency/img/logo.png" alt="logo"/></h4>
                              <div className="main-ring"><div className="slider-ring"></div></div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="slider-object1"><img src="design-agency/img/slider-object1.png" alt="slider1"/></div>
              <div className="slider-object2"><img src="design-agency/img/slider-object2.png" alt="slider2"/></div>
          </div>
            <div className="col-12 col-lg-6 banner-right-area wow slideInRight" data-wow-delay=".8s">
                <div className="row no-gutters">
                    <div className="col-12 bg-yellow height-50 d-flex align-items-center slider-right-box">
                        <div className="container-fluid">
                            <div className="detail-text text-right ml-auto mr-4">
                                <span className="sub-heading"><Translate app="catalogue" entity="item" eslug="section1" scope="default" slug="box-1-title" auto/></span>
                                <h4 className="heading"><Translate app="catalogue" entity="item" eslug="section1" scope="default" slug="box-1-subtitle" auto/></h4>
                                <p className="text"><Translate app="catalogue" entity="item" eslug="section1" scope="default" slug="box-1-content" auto/></p>
                                {
                                  /*
                                    <a className="btn white-trans-btn rounded-pill white-trans-btn-yellow-hvr">Get Started Now
                                    <span></span><span></span><span></span><span></span>
                                    </a>
                                  */
                                }
                            </div>
                        </div>
                        <div className="img-object wow slideInUp" data-wow-delay="1.8s">
                            <img src="design-agency/img/slider-object3.png" alt="slider3"/>
                        </div>
                    </div>
                    <div className="col-12 bg-green height-50 d-flex align-items-center slider-right-box">
                        <div className="container-fluid">
                            <div className="detail-text text-left mr-auto ml-4">
                               {
                                 /*
                                  <span className="sub-heading">Τι είναι?</span>
                                 */
                               }
                                <h4 className="heading"><Translate app="catalogue" entity="item" eslug="section1" scope="default" slug="box-2-title" auto/></h4>
                                <p className="text"><Translate app="catalogue" entity="item" eslug="section1" scope="default" slug="box-2-content" auto/></p>
                                {
                                  /*
                                  <a className="btn white-trans-btn rounded-pill white-trans-btn-green-hvr">Get Started Now
                                      <span></span><span></span><span></span><span></span>
                                  </a>
                                  */
                                }
                            </div>
                        </div>
                        <div className="img-object right wow slideInRight" data-wow-delay="1.8s">
                            <img src="design-agency/img/slider-object4.png" alt="slider4"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    );
};

export default ExportDefault;
