import Env from './Env';
import DateTime from './DateTime';
import Math from './Math';
import Dev from './Dev';
import String from './String';
import Const from './Const';
import Template from './Template';
import Api from './Api';
import Language from './Language';
import Literal from './Literal';
import Translate from './Translate';
import File from './File';
import State from './State';
import Auth from './Auth';
import Validate from './Validate';
import Url from './Url';
import Value from './Value';
import Components from './Components';

const Module = {
    Env,
    DateTime, 
    Math,
    Dev,
    String,
    Template,
    Api,
    Const,
    Language,
    Literal,
    Translate,
    File,
    State,
    Auth,
    Validate,
    Url,
    Value,
    Components
};

export default Module;