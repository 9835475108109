import React from 'react';

import Preploader from './home/preloader';
import Header from './home/header';
import Section1 from './home/section1';
import Section2 from './home/section2';
import Section3 from './home/section3';
import Section4 from './home/section4';
import Section5 from './home/section5';
import Section6 from './home/section6';
import Section7 from './home/section7';
import Footer from './home/footer';

const ExportDefault = function(props) {
  return (
    <React.Fragment>
      <Preploader {...props} />
      <Header {...props} />
      <Section1 {...props} />
      <Section2 {...props} />
      <Section3 {...props} />
      <Section4 {...props} />
      <Section5 {...props} />
      <Section6 {...props} />
      <Section7 {...props} />
      <Footer {...props} />
    </React.Fragment>
  )
};

export default ExportDefault;