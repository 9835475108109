import { Component } from '../Component';
import template from '../templates/file';

export default class File extends Component {
    template = template;

    onLoad(data) {
        super.onLoad(data);

        const account = this.getHelpers('env').get('default_account','system')

        this.setData({
            'params':{
                'account': account,
                'app': this.props.app || this.getHelpers('env').get('name'),
                'entity': this.props.entity,
                'entity_slug': this.props.eslug,
                'scope': this.props.scope || 'default',
            },
            'renderer': this.props.renderer,
        });

        this.scope();
    }

    scope() {
        const { 
            account,
            app,
            entity,
            entity_slug,
            scope
        } = this.getData('params');

        this.getHelpers('file').scope({account,app,entity,entity_slug,scope}).then(data => {
            this.setFiles(data);
        });
    }

    setFiles(data) {
        return this.setData({'default.files':data});
    }

    getRenderer() {
        return this.getData('renderer',() => '');
    }
}