import React from 'react';
import ReactDom from 'react-dom';
import Template from './templates/default';
import Helpers from './Helpers/Helpers';

class State {
    static states = {};

    static getStates() {
        return this.states;
    }

    static save(id, data, path) {
        path = id + (path ? `.${path}`: '');
        Helpers.Dev.setObjectValue(this.states,path,Helpers.Dev.cloneObject(data));
        return this;
    }

    static load(id, path) {
        path = id + (path ? `.${path}`: '');
        let data = Helpers.Dev.getObjectValue(this.states,path,{});
        
        if (data) {
            data = Helpers.Dev.cloneObject(data);
        }

        return data;
    }

    static clear(id) {
        if (id) {
            Helpers.Dev.deleteObjectValue(this.states,id);
        } else {
            this.states = {};
        }

        return this;
    }
}

class Component extends React.Component {
    template = Template;
    
    id = null;
    group = null;
    widget = false;
    mounted = false;
    persist = true;
    fullAuth = false;

    constructor(props) {
        super(props);
        this.state = {data:{}};
        this.init(props);
    }

    init(props) {
        const { id, group } = props || {};

        if (id) {
            this.setId(id);
        }

        if (group) {
            this.setGroup(group);
        }
        
        return this;
    }

    componentDidMount() {
        this.setMounted(true);
        this.subscribe();
        this.onLoad(this.loadState());        
    }
    
    componentWillUnmount() {
        this.setMounted(false);
        this.unsubscribe();
        this.onUnload();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onUpdate(prevProps,prevState,snapshot);
    }

    subscribe() {
        const id = this.getId();
        const group = this.getGroup();

        if (id || group.length) {
            this.getComponents().add(this);
        }

        return this;
    }

    unsubscribe() {
        const id = this.getId();
        const group = this.getGroup();

        if (id || group.length) {
            this.getComponents().removeById(id);
        }

        return this;
    }

    setTemplate(template) {
        this.template = template;
        return this;
    }

    getTemplate() {
        const defaultScope = 'default';
        const scope = this.getHelpers('template').getScope(defaultScope);
        let template = this.template;

        if (typeof template === 'object') {
            template = template[scope] ? template[scope]: template[defaultScope];
        }

        return template || null;
    }

    getHelpers(type) {
        type = type ? Helpers.String.cammelCase(type,'-'): null;
        return type ? Helpers[type]: Helpers;
    }

    getStateData() {
        const { data } = this.state || {};
        return data || {};
    }

    setData(paths, options) {
        let data = this.getStateData();
        const devHelper = this.getHelpers('dev');

        for (let path in paths) {
            devHelper.setObjectValue(data,path,paths[path]);
        }

        return this.save({data},options);
    }

    getData(path, defaultValue) {
        let data = this.getStateData();
        return path ? this.getHelpers('dev').getObjectValue(data,path,defaultValue): data;
    }

    resetData(data, options) {
        return this.save({data},options);
    }

    deleteData(path, options) {
        let data = this.getHelpers('dev').deleteObjectValue(this.getStateData(),path);
        return this.save({data},options);
    }

    dataExists(path) {
        return this.getHelpers('dev').hasObjectValue(this.getStateData(),path);
    }

    dataEqualsTo(path, value) {
        return this.getData(path) === value;
    }

    toggleData(path, options) {
        const { defaultValue } = options || {};
        const data = this.getData(path);

        this.setData({
            [path]: typeof data === 'boolean' ? !data: defaultValue
        },options);

        return this;
    }

    setLoaded() {
        return this.setData({'loaded': true});
    }

    isLoaded() {
        return this.dataExists('loaded');
    }

    setId(id) {
        this.id = id;
        return this;
    }

    getId() {
        return this.id;
    }

    setGroup(group) {
        this.group = group;
        return this;
    }

    getGroup() {
        const group = this.group || '';
        return Array.isArray(group) ? group: group.split(',');
    }

    hasGroup(group) {
        const newGroup = Array.isArray(group) ? group: group.split(',');
        const currentGroup = this.getGroup();
        let exists = false;

        newGroup.every(ngroup => {
            currentGroup.every(cgroup => {
                exists = ngroup === cgroup;

                if (exists) return false;

                return true;
            });

            if (exists) return false;

            return true;
        });

        return exists;
    }

    setMounted(mounted) {
        this.mounted = mounted;
        return this;
    }

    hasMounted() {
        return this.mounted === true;
    }

    isWidget() {
        return this.widget === true;
    }

    save(state, options) {
        state = state || {};
        options = options || {};

        if (this.hasMounted()) {
            const { cb } = options;
            this.saveState().setState(state,cb);
        }
        
        return this;
    }

    saveState() {
        const id = this.getId();
        const data = this.getData();
        
        if (this.persist && id && data){
            State.save(id,data);
        }

        return this;
    }

    loadState() {
        const id = this.getId();
        let data = null;

        if (this.persist && id) {
            data = State.load(id);
            this.setData(data);
        }

        return data;
    }

    render() {
        const Template = this.getTemplate();

        if (this.isFullAuth() && !this.isAuthLoaded()) {
            return '';
        }
        
        if (Template) {
            if (this.isWidget()) {
                return ReactDom.createPortal(<Template component={this} />,document.querySelector('#widgets'));
            } else {
                return <Template component={this} />
            }
        } 
    }

    onLoad(data) {
        if (this.props.onLoad) {
            this.props.onLoad(this,data);
        }
    }

    onUnload() {
        if (this.props.onUnload) {
            this.props.onUnload(this);
        }
    }

    onUpdate(prevProps, prevState, snapshot) {
        if (this.props.onUpdate) {
            this.props.onUpdate(this,prevProps,prevState,snapshot);
        }
    }

    refresh(options) {
        let data = this.getStateData();
        return this.save(data,options);
    }

    /**
     * 
     * Validation
     * 
     */

    validate(options) {
        const error = this.getHelpers('validate').validate(this.getData('default'),this.onValidate(options));
        
        if (error) {    
            this.setData({'error':error});
        } else {
            this.deleteData('error');
        }

        return !error;
    }

    onValidate(options) {
        return {};
    }

    hasError() {
        return this.dataExists('error');
    }

    hasSuccess() {
        return this.dataExists('success');
    }

    /**
     * 
     * Auth
     * 
     */
    getAuth() {
        return this.getHelpers('auth');
    }

    isFullAuth() {
        return this.fullAuth;
    }

    isAuthLoaded() {
        return this.getAuth().isLoaded();
    }

    onAuth(data) {
        //
    }

    notifyAuth(data) {
        const auth = this.getHelpers('auth');
        data = data || {};
        data.auth = auth;
        this.getComponents().exec('onAuth',data);
        return this;
    }
    
    /**
     * 
     * Helpers
     * 
     */
    getComponents() {
        return this.getHelpers('components');
    }

    getComponentsState() {
        return State;
    }

    getApp() {
        return this.getHelpers('const').get('app');
    }

    getPage() {
        return this.getHelpers('const').get('page');
    }

    switchPageLoader(onOff) {
        this.getComponents().findById('page-loader').forEach(loader => {
            loader.setData({'loading':onOff});
        });

        return this;
    }

    preventDefault(options) {
        const { e } = options;
        if (e) e.preventDefault();
        return this;
    }

    trans(slug) {
        return this.getHelpers('literal').trans(slug);
    }

    ucfirst(slug) {
        return this.getHelpers('literal').ucfirst(slug);
    }

    cloneObject(object) {
        return this.getHelpers('dev').cloneObject(object);
    }
}

export { Component, State };