import { Component } from '../Component';
import template from '../templates/translate';

const scopes = {};

export default class Translate extends Component {
    template = template;
    
    group = 'translation';

    onLoad(data) {
        super.onLoad(data);

        const { slug, auto } = this.props;
        const account = this.getHelpers('env').get('default_account','system')

        this.setData({
            'params':{
                'account': account,
                'app': this.props.app || this.getHelpers('env').get('name'),
                'entity': this.props.entity,
                'entity_slug': this.props.eslug,
                'scope': this.props.scope || 'default',
                'slug': slug
            },
            'renderer': this.props.renderer,
            'defaultValue': this.props.default || ''
        });

        /*if (slug) {
            this.slug();
        } else {
            this.scope();
        }*/

        if (auto) {
            this.scope();
        }
    }

    scope() {
        const { 
            account,
            app,
            entity,
            entity_slug,
            scope
        } = this.getData('params');

        const key = [account,app,entity,entity_slug,scope].join('-');

        if (!scopes[key]) {
            scopes[key] = {
                retrieve: true,
                data: null,
                components: []
            };

            this.getHelpers('translate').scope({account,app,entity,entity_slug,scope}).then(data => {
                const scope = scopes[key];

                scope.data = data;
                scope.retrieve = false;
                scope.components.push(this);
                scope.components.forEach(c => c.setTranslationData(data))
            });
        } else {
            const scope = scopes[key];
            const { retrieve, components, data } = scope;

            if (retrieve) {
                components.push(this);
            } else {
                this.setTranslationData(data);
            }
        }
    }

    setTranslationData(data) {
        return this.setData({'default.translation':data});
    }

    getSlug() {
        return this.getData('params.slug',null);
    }

    getRenderer() {
        return this.getData('renderer',() => '');
    }

    getTranslation(defaultValue = null) {
        return this.getData('default.translation',defaultValue);
    }

    translate() {
        let translation = this.getTranslation({
            translation: '',
            html: false
        });

        if (Array.isArray(translation)) {
            const slug = this.getSlug();

            if (slug) {
                translation.forEach(trans => {
                    if (trans.slug === slug) {
                        translation = trans;
                        return false;
                    }
                });
            }
        }

        return translation || this.getData('defaultValue');
    }

    isHtml(translation) {
        return translation.html || false;
    }
}