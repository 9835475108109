import React from 'react';

const ExportDefault = (props) => {
    let { className, width, height, buildLoad, buildSrc, buildError, buildAlt } = props;
    let style = {};

    if (width) {
        style.width = width;
    }

    if (height) {
        style.height = height;
    }

    const build = buildLoad || buildSrc;
    const errorSrc = buildError && buildError(props);
    const mainSrc = buildSrc && buildSrc(props);
    const alt = buildAlt && buildAlt(props);

    return (
        <img alt={alt} style={style} src={build && build(props)} onLoad={(e) => {
            if (buildLoad) {
                buildLoad = null;
                e.target.src = mainSrc;
            }
        }} onError={(e) => {
            if (buildError) {
                buildError = null;
                buildLoad = null;
                e.target.src = errorSrc;
            }
        }} className={className}/>
    );
};  

export default ExportDefault;