import React from 'react';
import Translate from '../../../../../modules/Core/Components/Translate';
import File from '../../../../../modules/Core/Components/File';
import Image from '../../../../../modules/Core/templates/widget/image';

const ExportDefault = function(props) {
    const { component } = props;
    const account = component.getHelpers('env').get('default_account');

    return (
        <section className="portfolio-sec padding-top padding-bottom bg-light-gray" id="portfolio-sec">
            <div className="container">
                <div className="row portfolio-heading-area text-center text-lg-left">
                    <div className="col-12">
                        <h4 className="heading wow fadeInLeft text-center"><Translate app="catalogue" entity="item" eslug="section4" scope="default" slug="title" auto/></h4>
                        <p className="text wow fadeInRight">
                            <Translate app="catalogue" entity="item" eslug="section4" scope="default" slug="sub-title" auto/>
                        </p>
                    </div>
                </div>
                <div className="row position-relative">
                    <div className="col-12">
                        <div className="portfolio-carousel owl-carousel owl-item">
                            <File app="catalogue" entity="item" eslug="section4" scope="slider" renderer={(props) => {
                                const { component } = props;

                                return component.getData('default.files',[]).map(file => {
                                    const {
                                        entity,
                                        entity_slug,
                                        slug
                                    } = file;

                                    return (
                                        <div key={`slide-${slug}`} className="item">
                                            <div className="portfolio-image">
                                                <Image buildSrc={() => component.getHelpers('url').createAccountImageUrl(account,'catalogue',entity,entity_slug,'slider',slug)} alt="pic"/>
                                            </div>
                                        </div>
                                    );
                                });
                            }}/>
                        </div>
                    </div>
                    <span className="portfolio-links" id="portfolio-arr-left"><i className="fas fa-angle-left"></i> </span>
                    <span className="portfolio-links" id="portfolio-arr-right"><i className="fas fa-angle-right"></i> </span>
                </div>
            </div>
        </section>
    );
};

export default ExportDefault;