import Page from '../../../modules/Core/Components/Page';
import templateDefault from '../../templates/default/pages/home';

export default class Home extends Page {
    title = 'home';
    
    template = templateDefault;

    onLoad(data) {
        super.onLoad(data);

        this.addScripts({
            'bundle.min': '/vendor/js/bundle.min',
            'jquery.appear': '/vendor/js/jquery.appear',
            'jquery.fancybox.min': '/vendor/js/jquery.fancybox.min',
            'owl.carousel.min': '/vendor/js/owl.carousel.min',
            'parallaxie.min': '/vendor/js/parallaxie.min',
            'wow.min': '/vendor/js/wow.min',
            'jquery.cubeportfolio.min': '/vendor/js/jquery.cubeportfolio.min',
            'TweenMax.min': '/vendor/js/TweenMax.min',
            'circle-progress.min': '/design-agency/js/circle-progress.min',
            'contact_us': '/vendor/js/contact_us',
            'script': '/design-agency/js/script',
        },true,false,(name) => {
            /*
            if (name === 'jquery.magnific-popup.min') {
                this.getComponents().findById('slider').forEach(component => {
                    component.setData({
                        'default.ready': true
                    });
                });
            }
            */
        });
    }
}